import React from "react";

import { useIntercom } from "react-use-intercom";

import { Link } from "components/common/Link";
import { useShowNeedHelpBanner } from "components/dashboard/Checklist/NeedHelpBanner";

import { Briefcase, ChevronDown, Headset } from "@puzzle/icons";

import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import { useAppRouter } from "lib/useAppRouter";

import { Route } from "lib/routes";
import links from "lib/links";

import { Menu } from "@puzzle/ui";
import { Button, Text } from "ve";
import { S, colors } from "@puzzle/theme";

import { OnboardingLaunchpadButton } from "./OnboardingLaunchpadButton";
import { zIndex } from "@puzzle/utils";

const HelpMenu = ({
  showChatWithUs,
  showAskAnAccountant,
  showShareFeedback,
}: {
  showChatWithUs: boolean;
  showAskAnAccountant: boolean;
  showShareFeedback: boolean;
}) => {
  const { show: showIntercom } = useIntercom();
  const { goToPath } = useAppRouter();

  return (
    <Menu
      css={{ zIndex: zIndex("menu") }}
      trigger={
        <Button
          variant="minimal"
          css={{ display: "flex", flexDirection: "row", alignItems: "center", gap: S.$1h }}
          prefixElement={
            <Headset size={20} style={{ marginRight: S.$0h }} color={colors.white700} />
          }
          suffixElement={<ChevronDown size={16} color={colors.white700} />}
        >
          <Text variant="bodyS" color="neutral100">
            Get help
          </Text>
        </Button>
      }
    >
      {showChatWithUs && (
        <Menu.Item
          onClick={() => {
            showIntercom();
          }}
        >
          <Text variant="bodyS" color="neutral200">
            Chat with us
          </Text>
        </Menu.Item>
      )}
      {showAskAnAccountant && (
        <Menu.Item
          onClick={() => {
            goToPath(Route.askAccountant);
          }}
        >
          <Text variant="bodyS" color="neutral200">
            Ask an accountant
          </Text>
        </Menu.Item>
      )}
      <Menu.Item>
        <Link href={links.helpCenter} external target="_blank">
          <Text variant="bodyS" color="neutral200">
            Help center
          </Text>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link href={links.blog} external target="_blank">
          <Text variant="bodyS" color="neutral200">
            Blog
          </Text>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link href={links.foundersGuide} external target="_blank">
          <Text variant="bodyS" color="neutral200">
            Founder's guide
          </Text>
        </Link>
      </Menu.Item>
      {showShareFeedback && (
        <Menu.Item
          onClick={() => {
            showIntercom();
          }}
        >
          <Text variant="bodyS" color="neutral200">
            Share feedback
          </Text>
        </Menu.Item>
      )}
    </Menu>
  );
};

const HireAnAccountant = () => {
  return (
    <Button
      id="header-hire-an-accountant"
      prefixElement={<Briefcase size={20} css={{ marginRight: S.$0h }} />}
      css={{
        marginRight: S.$2,
        color: colors.white700,
      }}
      variant="minimal"
    >
      <Link href={links.hireAccountant}>
        <Text variant="bodyS" color="neutral100">
          Hire an accountant
        </Text>
      </Link>
    </Button>
  );
};

export const HeaderButtons = ({ hangRight = false }: { hangRight?: boolean }) => {
  // TODO: Discuss with Product whether we could lazy load this information
  // https://linear.app/puzzlefin/issue/PER-286/explore-lazy-loading-non-urgent-features
  const { showHireAnAccountant, showChatWithUs, showAskAnAccountant, showShareFeedback, loading } =
    useShowNeedHelpBanner();

  const showOnboardingLaunchpad = isPosthogFeatureFlagEnabled(FeatureFlag.onboardingLaunchpad);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: hangRight ? "auto" : undefined,
      }}
    >
      {showOnboardingLaunchpad && !loading && <OnboardingLaunchpadButton />}
      {showHireAnAccountant && <HireAnAccountant />}
      <HelpMenu
        showChatWithUs={showChatWithUs}
        showAskAnAccountant={showAskAnAccountant}
        showShareFeedback={showShareFeedback}
      />
    </div>
  );
};
